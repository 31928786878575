.chakra-ui-light {
  --game-table-gradient-end: #fff;
}
.chakra-ui-dark {
  --game-table-gradient-end: var(--chakra-colors-darkGray);
}

.game-table-wrapper {
  width: 100%;
  display: block;
  position: relative;
}
.game-table-wrapper:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    var(--game-table-gradient-end)
  );
  width: 10%;
  opacity: 0;
  transition: opacity 0.2s;
}
.game-table-wrapper.shadow:after {
  opacity: 1;
}

.game-table tr.status-notStarted {
  opacity: 0.5;
}
.game-table tr.status-inProgress,
.game-table td.current-user {
  background: rgba(255, 255, 255, 0.05);
}
.chakra-ui-light .game-table tr.status-inProgress,
.chakra-ui-light .game-table td.current-user {
  background: rgba(0, 0, 0, 0.03);
}

.spin-infinite {
  animation: spin 4s ease infinite;
  animation-delay: 1s;
}

@keyframes spin {
  0% {
    transform: rotate(-180deg);
  }
  25% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Current Card Display */

.current-card-container {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.current-card {
  position: absolute;
  width: 100%;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 5px;
  transition: opacity ease-in-out 2s;
}
.current-card.active {
  opacity: 1;
}
.current-card-container.no-shadow .playing-card {
  box-shadow: none;
  border: none;
}
