@import url('https://fonts.googleapis.com/css2?family=Arbutus+Slab&display=swap');

.playing-card-wrapper {
  background-color: white;
  border-radius: 15px;
}

.playing-card {
  user-select: none;
  aspect-ratio: 0.7;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  border-radius: 15px;
}
.chakra-ui-dark .playing-card {
  border: 1px solid #111;
  box-shadow: 2px 3px 8px #111;
}
.chakra-ui-light .playing-card {
  border: 1px solid #bbb;
  box-shadow: 2px 3px 8px #ddd;
}
.playing-card.black {
  color: #000;
}
.playing-card.red {
  color: #bd2f2f;
}

.playing-card .card-rank {
  /* The font size is also dynamically updated based on width by JS */
  font-size: 10vw;
  font-weight: bold;
  font-family: 'Arbutus Slab', serif;
  line-height: 1;
  margin-bottom: 20px;
  pointer-events: none;
}
.playing-card .card-suit {
  position: absolute;
  width: 30%;
}
.playing-card .card-suit.card-suit-1 {
  left: 15px;
  top: 15px;
}
.playing-card .card-suit.card-suit-2 {
  right: 15px;
  bottom: 15px;
  transform: rotate(180deg);
}
